<template>
  <v-carousel
    v-model="model"
    :height="getHeight()"
    continous
    show-arrows-on-hover
    hide-delimiter-background
    :hide-delimiters="$vuetify.breakpoint.mobile"
  >
    <template v-slot:prev="{ on, attrs }">
      <v-btn
        v-bind="attrs"
        v-on="on"
        icon
      >
        <v-icon
          color="primary lighten-1"
          small
        >
          fas fa-chevron-left
        </v-icon>
      </v-btn>
    </template>
    <template v-slot:next="{ on, attrs }">
      <v-btn
        v-bind="attrs"
        v-on="on"
        icon
      >
        <v-icon
          color="primary lighten-1"
          small
        >
          fas fa-chevron-right
        </v-icon>
      </v-btn>
    </template>
    <v-carousel-item
      v-for="(item, idx) in top_banners"
      :key="idx"
      :src="item != null ? $store.state.imagePath + item.image_path : img"
    >
    </v-carousel-item>
  </v-carousel>
</template>

<script>
export default {
  data: () => ({
    model: 0,
    top_banners: null,
  }),
  methods: {
    getHeight() {
      if (this.$vuetify.breakpoint.xl) {
        return 450
      } else if (this.$vuetify.breakpoint.lg) {
        return 335
      } else {
        return 200
      }
    },
    getTopBanner() {
      this.$api
        .get('homes/top_banner')
        .then((res) => {
          this.top_banners = res.data.data
        })
        .catch((err) => {
          console.log(err)
        })
    },
  },
  created() {
    this.getTopBanner()
  },
}
</script>
